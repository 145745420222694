/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
  font-family: codewallet-logo-font;
  src: local("OPTIOpus"), url("../fonts/OPTIOpus.otf") format("truetype");
  font-weight: bold;
}

.page-panel {
  position: absolute;
  left: 256px;
  right: 0px;
  min-height: 500px;
  top: 64px;
}

.page-panel.welcome-page {
  left: 0px;
  bottom: 0px;
}

.page-panel.welcome-page .welcome-paragraph{
  text-align: center;
}

.page-panel.login-page {
  left: 0px;
  bottom: 0px;
}


.uppy-Dashboard-inner {
  border: none !important;
}

.uppy-Dashboard-AddFiles-title {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.uppy-Dashboard-AddFiles-title button::before {
  content: '';
  width: 64px;
  height: 64px;
  background: url("../icons/upload_icon.svg");
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 60px);
  transform: translate(-50%, -50%);
}

.uppy-Dashboard-AddFiles-title button {
  color: #000000;
  font-weight: bold !important;
  position: absolute;
  text-decoration: underline;
  left: 50%;
  top: calc(50% + 12px);
  transform: translate(-50%, -50%);
}

.uppy-Dashboard-AddFiles-title button:hover {
  border-bottom: none !important;
  font-size: 12px !important;
}

.search-field {
  border-radius: 0px;
}

#modal-add-user-description {
  margin-top: 2px;
}

.page-panel #add-user {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50px;
  height: inherit;
}

.user-table-head {
  user-select: none;
  color: #86898A !important;
}

.user-table-head.allow-order {
  cursor: pointer;
}

.user-table-head.selected,
.user-table-head.allow-order.selected svg {
  color: black !important;
}

.user-table-head.allow-order svg {
  position: relative;
  top: 7px;
}

.error-username{
  color: red;
}

.cw-input-box .MuiOutlinedInput-notchedOutline {
  border-color: lightgray !important;
}


/* Variables */

:root {
  --black: rgba(0, 0, 0, 1);
  --lochmara: rgba(0, 113, 188, 1);
  --alertssuccessbase: rgba(12, 175, 96, 1);
  --greyscale900: rgba(17, 24, 39, 1);
  --new-car: rgba(39, 77, 229, 1);
  --primarybase: rgba(39, 163, 118, 1);
  --background: rgba(43, 43, 43, 1);
  --othersblue: rgba(46, 120, 238, 1);
  --greyscale700: rgba(49, 58, 72, 1);
  --background---secondary: rgba(59, 59, 59, 1);
  --greyscale600: rgba(103, 116, 136, 1);
  --caption--label-text: rgba(133, 133, 132, 1);
  --others--portage: rgba(140, 98, 255, 1);
  --greyscale500: rgba(160, 174, 192, 1);
  --call-to-action: rgba(162, 89, 255, 1);
  --greyscale--400: rgba(203, 213, 224, 1);
  --alertserrorbase: rgba(224, 49, 55, 1);
  --blue-chalk: rgba(228, 233, 255, 1);
  --pomegranate: rgba(229, 75, 36, 1);
  --lily-white: rgba(230, 245, 255, 1);
  --greyscale300: rgba(232, 234, 235, 1);
  --otherslight-blue: rgba(234, 242, 255, 1);
  --greyscale200: rgba(240, 241, 243, 1);
  --titan-white: rgba(243, 239, 255, 1);
  --greyscale50: rgba(249, 249, 249, 1);
  --chardon: rgba(255, 243, 241, 1);
  --text: rgba(255, 255, 255, 1);

  --font-size-xxs: 14px;
  --font-size-s: 10px;
  --font-size-s: 22px;
  --font-size-m: 12px;
  --font-size-m: 24px;
  --font-size-l: 38px;
  --font-size-l: 48px;
  --font-size-xl: 51px;
  --font-size-xxl: 18px;
  --font-size-xxl: 67px;
  --font-size-xxxl: 20px;
  --font-size-xxxxl: 32px;
  --font-size-xs2: 16px;

  --font-family-inter: "Inter";
  --font-family-manrope: "Manrope";
  --font-family-manrope-semibold: "Manrope-SemiBold";
  --font-family-space_mono: "Space Mono";
  --font-family-work_sans: "Work Sans";
}

/* Classes */

.bodysmallregular {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
}
.bodymediumbold {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxs);
  letter-spacing: 0.2px;
  font-weight: 700;
  font-style: normal;
}
.bodymediumregular {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxs);
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
}
.bodylargemedium {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xs2);
  letter-spacing: 0.2px;
  font-weight: 500;
  font-style: normal;
}
.heading--h1 {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-l);
  letter-spacing: -0.5px;
  font-weight: 700;
  font-style: normal;
}
.worksans-semi-bold-white-16px {
  color: var(--text);
  font-family: var(--font-family-work_sans);
  font-size: var(--font-size-xs2);
  font-weight: 600;
  font-style: normal;
}
.bodylargebold {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xs2);
  letter-spacing: 0.3px;
  font-weight: 700;
  font-style: normal;
}
.spacemono-bold-white-24px {
  color: var(--text);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.bodymediummedium {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxs);
  letter-spacing: 0px;
  font-weight: 500;
  font-style: normal;
}
.bodyxlargesemibold {
  font-family: var(--font-family-manrope-semibold);
  font-size: var(--font-size-xxl);
  letter-spacing: 0px;
  font-weight: 600;
  font-style: normal;
}
.bodyxlargesemibold {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxl);
  letter-spacing: 0px;
  font-weight: 600;
  font-style: normal;
}
.bodymediumsemibold {
  font-family: var(--font-family-manrope-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0px;
  font-weight: 600;
  font-style: normal;
}
.bodysmallbold {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  letter-spacing: 0.2px;
  font-weight: 700;
  font-style: normal;
}
.bodymediumsemibold {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxs);
  letter-spacing: 0px;
  font-weight: 600;
  font-style: normal;
}
.bodyxsmallregular {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
}
.spacemono-normal-white-14px {
  color: var(--text);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  font-style: normal;
}
.heading--h5 {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0px;
  font-weight: 700;
  font-style: normal;
}
.worksans-semi-bold-white-22px {
  color: var(--text);
  font-family: var(--font-family-work_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.spacemono-bold-white-67px {
  color: var(--text);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
}
.heading--h3 {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxxl);
  letter-spacing: 0px;
  font-weight: 700;
  font-style: normal;
}
.bodyxsmallbold {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  letter-spacing: 0.2px;
  font-weight: 700;
  font-style: normal;
}
.worksans-bold-white-24px {
  color: var(--text);
  font-family: var(--font-family-work_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.worksans-semi-bold-white-67px {
  color: var(--text);
  font-family: var(--font-family-work_sans);
  font-size: var(--font-size-xxl);
  font-weight: 600;
  font-style: normal;
}
.bodysmallmedium {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  letter-spacing: 0px;
  font-weight: 500;
  font-style: normal;
}