@import url("https://fonts.googleapis.com/css?family=Manrope:500,700,400|Inter:400");

.screen a {
  display: contents;
  text-decoration: none;
}

.onboarding {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
}

.onboarding .cw-content {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  padding: 50px 50px 100px;
  position: relative;
}

.review {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  align-self: stretch;
  color: var(--text);
  font-weight: 700;
  line-height: 57.6px;
  margin: 0px;
  position: relative;
  white-space: nowrap;
  font-size: min(3.3vw, 3rem);
}

.say-goodbye-to-code {
  align-self: stretch;
  color: var(--text);
  font-family: var(--font-family-inter);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33.2px;
  position: relative;
  font-size: min(1.5vw, 1.25rem);
  margin: 0px;
}

.onboarding .logo {
  align-items: center;
  display: flex;
  gap: 1px;
  position: relative;
  width: fit-content;
}

.cw-login-form {
    position: fixed;
    top: 0;
    right: 0;
    left: 50%;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cw-login-form .cw-content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

  .cw-login-form .cw-content .cw-input-box.cw-checkbox-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: 0.625rem;
  }

  .cw-login-form .cw-content .cw-input-box.cw-checkbox-link a {
    text-decoration: none;
  }

  .cw-login-form .cw-content .cw-input-box > * {
    width: 100%;
  }

  .cw-login-form .cw-content .cw-input-box > label {
    margin-top: 1.5rem;
    margin-bottom: 0.625rem;
  }

  .cw-login-form .cw-content .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
  }

  .cw-login-form .cw-content .Mui-focused .MuiOutlinedInput-notchedOutline,
  .cw-login-form .cw-content .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: green !important;
  }


  .cw-login-form .cw-content h1 {
    font-family: var(--font-family-manrope);
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  .cw-login-form .cw-content .cw-input-box button {
    font-family: var(--font-family-manrope);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 2.25rem;
  }


  .cw-login-form .cw-content .cw-input-box.cw-login-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cw-login-form .cw-content .cw-input-box.cw-login-button label {
    color: red;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
  }

  .cw-login-form .cw-content .cw-input-box.cw-login-button label svg {
    margin-right: 0.5rem;
    font-size: 1rem;
    position: relative;
    top: -0.0625rem;
  }