.topbar {
    position: fixed;
    width: 100%;
    height: 64px;
    left: 0px;
    top: 0px;
    background: #000000;
    z-index: 1000;
    user-select: none;
}

.logo {
    position: relative;
    height: 100%;
    display: inline-block;
}

.logo .icon {
    position: relative;
    margin: 10px 10px;
    width: 30px;
    height: 100%;
    display: inline-block;
}

.logo .icon .circle {
    position:relative;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 10px;
}

.logo .title {
    position: absolute;
    margin-left: 10px;
    height: 100%;
    top: -5px;
    color: white;
    display:inline-flex;
    align-items:center;
    font-family: codewallet-logo-font;
    font-size: 50px;
    letter-spacing: 8px;
}

.topbar .logo .icon .image {
    height: 49.67px;
    min-width: 156px;
    object-fit: cover;
    position: relative;
}

.tenant-user-details {
    position: absolute;
    top: 0px;
    right: 40px;
}

.tenant-user-details .text {
    color: white;
    display: inline-block;
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
}

.tenant-user-details > div > button {
    padding: 20px 22px 0px 22px;
    text-transform: capitalize;
}