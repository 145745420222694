.search-hash{
    margin-left: "0.6vw";

}


.button-search{
    margin-left: "0.9vw";
    margin-right: "0.9vw";
    height: 5vh;
}